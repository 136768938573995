import { __decorate } from "tslib";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
let QrScannerModalService = class QrScannerModalService {
    constructor() {
        this._scanResult$ = new BehaviorSubject(this._scanResult);
    }
    scanDone($event) {
        this._scanResult = $event;
        this._scanResult$.next($event);
    }
    getScanResult() {
        return this._scanResult$.asObservable();
    }
};
QrScannerModalService = __decorate([
    Injectable()
], QrScannerModalService);
export { QrScannerModalService };
