import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
let ShopService = class ShopService {
    constructor() {
        this.products = [];
        this.localProducts = [];
        this.product = {};
    }
    addProduct(id, quantity, product) {
        if (localStorage.getItem('products')) {
            this.products = JSON.parse(localStorage.getItem('products'));
        }
        this.products.push({
            id: id,
            quantity: quantity,
            product: JSON.stringify(product)
        });
        localStorage.setItem('products', JSON.stringify(this.products));
    }
    productExists(id, product) {
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id) {
                return true;
            }
        }
        return false;
    }
    getProducts() {
        if (!localStorage.getItem('products')) {
            localStorage.setItem('products', JSON.stringify([]));
        }
        this.localProducts = JSON.parse(localStorage.getItem('products'));
        if (this.localProducts) {
            for (let i = 0; i < this.localProducts.length; i++) {
                this.localProducts[i].product = JSON.parse(this.localProducts[i].product);
            }
        }
        return this.localProducts;
    }
    updateProduct(id, quantity) {
        this.products = JSON.parse(localStorage.getItem('products'));
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id) {
                this.products[i].quantity = this.products[i].quantity + quantity;
                localStorage.setItem('products', JSON.stringify(this.products));
            }
        }
    }
    updateProductByIndex(index, quantity) {
        this.products = JSON.parse(localStorage.getItem('products'));
        for (let i = 0; i < this.products.length; i++) {
            if (i === index) {
                this.products[i].quantity = this.products[i].quantity + quantity;
                localStorage.setItem('products', JSON.stringify(this.products));
            }
        }
    }
    setProduct(id, quantity) {
        this.products = JSON.parse(localStorage.getItem('products'));
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id) {
                this.products[i].quantity = quantity;
                localStorage.setItem('products', JSON.stringify(this.products));
            }
        }
    }
    setProductByIndex(index, quantity) {
        this.products = JSON.parse(localStorage.getItem('products'));
        for (let i = 0; i < this.products.length; i++) {
            if (i === index) {
                this.products[i].quantity = quantity;
                localStorage.setItem('products', JSON.stringify(this.products));
            }
        }
    }
    removeProduct(id) {
        for (let i = 0; i < this.products.length; i++) {
            if (i === id) {
                this.products.splice(i, 1);
            }
        }
        localStorage.setItem('products', JSON.stringify(this.products));
    }
    calculateAll() {
        this.localProducts = JSON.parse(localStorage.getItem('products'));
        let sum = 0;
        for (let i = 0; i < this.localProducts.length; i++) {
            sum = sum + (this.localProducts[i].quantity * JSON.parse(this.localProducts[i].product).price);
        }
        return sum;
    }
};
ShopService = __decorate([
    Injectable()
], ShopService);
export { ShopService };
