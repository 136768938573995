import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
let AuthGuard = class AuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate() {
        if (!this.authService.isAuthenticated()) {
            this.router.navigateByUrl('');
            return false;
        }
        return true;
    }
};
AuthGuard.ctorParameters = () => [
    { type: AuthService },
    { type: Router }
];
AuthGuard = __decorate([
    Injectable()
], AuthGuard);
export { AuthGuard };
