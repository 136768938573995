import { __decorate } from "tslib";
import { EventEmitter, Injectable } from '@angular/core';
import { WebClientService } from './webclient-settings.service';
import { BehaviorSubject } from 'rxjs';
let LanguageService = class LanguageService {
    constructor(webSettingsService) {
        this.webSettingsService = webSettingsService;
        this.lang = '';
        this.lang$ = new BehaviorSubject(this.lang);
        this.languageFile = {};
        this.currentRoute = '/';
        this.spinnerOn = true;
        this.webSettingsService.data.subscribe((data) => {
            this.languageFile = this.webSettingsService.getLanguageFile();
            this.lang = this.getCurrentLanguage();
            if (this.lang === undefined) {
                this.lang = localStorage.getItem('language');
            }
            this.lang$.next(this.lang);
            this.spinnerOn = false;
        });
        this.setSettingsInterval();
        this.language = new EventEmitter();
    }
    languageChange(lang) {
        this.language.emit(lang);
    }
    switch_language(language) {
        localStorage.setItem('language', language);
        this.lang = language;
        this.languageChange(language);
        clearInterval(this.getSettingsInterval);
        this.setSettingsInterval();
        this.webSettingsService.getSettings(language).subscribe((data) => {
            this.currentRoute = window.location.pathname;
            this.webSettingsService.redirectToHome(this.currentRoute);
            this.spinnerOn = false;
        });
    }
    setSettingsInterval() {
        this.getSettingsInterval = setInterval(() => {
            this.webSettingsService.getSettingsByLang(this.lang);
        }, 180000);
    }
    getAvailableLanguages() {
        return this.webSettingsService.getAvailableLanguages();
    }
    getCurrentLanguage() {
        return this.webSettingsService.getCurrentLanguage();
    }
    getChosenLanguage() {
        return this.lang$.asObservable();
    }
};
LanguageService.ctorParameters = () => [
    { type: WebClientService }
];
LanguageService = __decorate([
    Injectable()
], LanguageService);
export { LanguageService };
