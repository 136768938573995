import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
let PersonalVerificationService = class PersonalVerificationService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.postOffices = [];
    }
    loadPostOffices() {
        return new Promise((resolve) => {
            this.httpClient.get('personalverification/getallpostoffices').subscribe((data) => {
                this.postOffices = data;
                resolve(true);
                return data;
            }, (error) => {
                resolve(false);
            });
        });
    }
    getAllPostOffices() {
        return this.postOffices;
    }
};
PersonalVerificationService.ctorParameters = () => [
    { type: SharedHttp }
];
PersonalVerificationService = __decorate([
    Injectable()
], PersonalVerificationService);
export { PersonalVerificationService };
