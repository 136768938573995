import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedHttp } from './http-client.service';
import { BehaviorSubject } from 'rxjs';
let WebClientService = class WebClientService {
    constructor(router, httpClient) {
        this.router = router;
        this.httpClient = httpClient;
        this.routesObject = {};
        this.webSettings = {};
        this.langFile = {};
        this.settings = {};
        this.settings$ = new BehaviorSubject(this.settings);
        this.dataSource = new BehaviorSubject(null);
        this.data = this.dataSource.asObservable();
        this.errorSubject = new BehaviorSubject(false);
        this.value = this.errorSubject.asObservable();
        const lang = (localStorage.getItem('language') && localStorage.getItem('language') !== 'undefined') ? localStorage.getItem('language') : null;
        this.httpClient.get('webclient/settings' + (lang ? '?language=' + lang : '')).subscribe((data) => {
            if (data['settings'].length === 0 || data['language_file'].length === 0) {
                this.errorSubject.next(true);
            }
            else {
                this.setSettings(data);
                this.dataSource.next(true);
                this.errorSubject.next(false);
            }
        }, (error) => {
            this.errorSubject.next(true);
        });
    }
    getSettings(language = null) {
        if (language) {
            this.getSettingsByLang(language);
        }
        return this.settings$.asObservable();
    }
    getSettingsByLang(language) {
        this.httpClient.get('webclient/settings?language=' + language).subscribe((data) => {
            if (data['settings'].length === 0 || data['language_file'].length === 0) {
                this.errorSubject.next(true);
            }
            else {
                this.setSettings(data);
                this.dataSource.next(true);
                this.errorSubject.next(false);
            }
        }, (error) => {
            this.errorSubject.next(true);
        });
    }
    setSettings(data) {
        this.settings$.next(data);
        this.settings = data;
        this.webSettings = this.settings.settings;
        this.langFile = this.settings.language_file;
        this.routesObject = this.settings.routes;
        if ((!localStorage.getItem('language') || localStorage.getItem('language') === 'undefined') && this.settings.defaultLanguage) {
            localStorage.setItem('language', this.settings.defaultLanguage);
        }
        if (this.settings.languages) {
            localStorage.setItem('all_langs', JSON.stringify(this.settings.languages));
        }
    }
    getLanguageFile() {
        return this.langFile;
    }
    getAvailableLanguages() {
        return this.settings.languages;
    }
    getCurrentLanguage() {
        return this.settings.currentLanguage;
    }
    getDefaultLanguage() {
        return this.settings.defaultLanguage;
    }
    applySettings(key) {
        let settingsIsOn = false;
        this.settings$.subscribe({
            next: (data) => {
                if (data.settings !== null && typeof data.settings !== 'undefined') {
                    settingsIsOn = data.settings[key] === '1' ||
                        data.settings[key] === 'true' ||
                        data.settings[key] === 'on' ||
                        data.settings[key] === 'ON' ||
                        data.settings[key] === 'redirect';
                }
            }
        });
        return settingsIsOn;
    }
    getWebSetting(key) {
        let webSetting = null;
        this.settings$.subscribe({
            next: (data) => {
                if (data.settings !== null && typeof data.settings !== 'undefined') {
                    if (key === 'DEFAULT_ROUTE') {
                        webSetting = data.settings[key].trim();
                    }
                    else {
                        webSetting = data.settings[key];
                    }
                }
            }
        });
        return webSetting;
    }
    redirectToHome(route) {
        if (route.indexOf('/news') !== -1) {
            route = '/news';
        }
        if (this.webSettings) {
            const key = this.routesObject[route];
            if (route in this.routesObject && this.applySettings(key)) {
                this.router.navigateByUrl('');
            }
            if (this.routesObject && (route in this.routesObject)) {
                const navigateTo = this.routesObject[route];
                this.router.navigateByUrl(navigateTo);
            }
        }
    }
    checkForKey(key) {
        if (this.langFile !== null && typeof this.langFile !== 'undefined') {
            if (key in this.langFile) {
                return !(this.langFile[key] === '' || this.langFile[key].trim().length === 0);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    emptySettings() {
        if ((this.webSettings !== null && typeof this.webSettings !== 'undefined') ||
            (this.langFile !== null && typeof this.langFile !== 'undefined')) {
            return !(this.webSettings.length === 0 || this.langFile.length === 0);
        }
        else {
            return false;
        }
    }
    getKeyValue(key) {
        let keyValue = '';
        this.settings$.subscribe({
            next: (data) => {
                if (data.language_file !== null && typeof data.language_file !== 'undefined') {
                    if (key in data.language_file) {
                        keyValue = data.language_file[key].trim();
                    }
                }
            }
        });
        return keyValue;
    }
    getCustomValue(key) {
        const substringValue = '.VALUE';
        let customValue;
        this.settings$.subscribe({
            next: (data) => {
                if (data.settings !== null && typeof data.settings !== 'undefined') {
                    customValue = (key.indexOf(substringValue) !== -1) ? data.settings[key] : '';
                }
                else {
                    return '';
                }
            }
        });
        return customValue;
    }
};
WebClientService.ctorParameters = () => [
    { type: Router },
    { type: SharedHttp }
];
WebClientService = __decorate([
    Injectable()
], WebClientService);
export { WebClientService };
