import { __decorate } from "tslib";
import { Pipe } from '@angular/core';
import { WebClientService } from "../services/webclient-settings.service";
let CurrencyFormatPipe = class CurrencyFormatPipe {
    constructor(webSettingsService) {
        this.webSettingsService = webSettingsService;
        this.thousandsSeparator = '';
        this.decimalSeparator = '';
    }
    transform(value, format) {
        this.thousandsSeparator = this.webSettingsService.getWebSetting('NUMBER_FORMAT.THOUSANDS_SEPARATOR');
        this.decimalSeparator = this.webSettingsService.getWebSetting('NUMBER_FORMAT.DECIMAL_SEPARATOR');
        if (typeof value !== 'undefined' && value !== null) {
            if (format === 'justReplaceDot') {
                return (parseFloat(value + '')).toString().replace('.', this.decimalSeparator);
            }
            else if (format === 'replaceCommaWithDot') {
                return value.toString().replace(",", ".");
            }
            else if (format === 'replaceDotWithComma') {
                return value.toString().replace(".", ",");
            }
            else if (format === 'revertCommaAndDot') {
                let replaceDot = value.toString().replace('.', '|');
                let newchar = '.';
                let replaceComma = replaceDot.toString().split(',').join(newchar);
                return replaceComma.toString().replace('|', ',');
            }
            else if (format === 'decimal') {
                if (value.toString().indexOf('.') !== -1) {
                    return (Math.round(parseFloat(value + '') * 100) / 100).toFixed(2).toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                }
                else {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',00';
                }
            }
            else if (format === 'withoutMathRound') {
                let s = '';
                let temp;
                let num = parseFloat(value + '').toString().split('.');
                let n = num[0];
                while (n.length > 3) {
                    temp = n.substring(n.length - 3);
                    s = '.' + temp + s;
                    n = n.slice(0, -3);
                }
                if (n) {
                    s = n + s;
                }
                if (num[1]) {
                    s += ',' + num[1].toString();
                }
                return s;
            }
            else if (format === 'notDecimal') {
                if (value.toString().indexOf('.') !== -1) {
                    return (Math.round(parseFloat(value + ''))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + this.thousandsSeparator);
                }
                else {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + this.thousandsSeparator);
                }
            }
            else if (format === 'percents') {
                if (value.toString().indexOf('.') !== -1) {
                    if (value.toString().indexOf('.00') !== -1) {
                        return value.toString().split('.')[0];
                    }
                    else {
                        return value.toString().replace('.', ',');
                    }
                }
                else {
                    return value + '';
                }
            }
        }
        else {
            return '';
        }
    }
};
CurrencyFormatPipe.ctorParameters = () => [
    { type: WebClientService }
];
CurrencyFormatPipe = __decorate([
    Pipe({
        name: 'currency_format',
        pure: false
    })
], CurrencyFormatPipe);
export { CurrencyFormatPipe };
