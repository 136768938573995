import { __decorate } from "tslib";
import { Pipe } from '@angular/core';
import { WebClientService } from '../services/webclient-settings.service';
let TranslateObjectPipe = class TranslateObjectPipe {
    constructor(wcss) {
        this.wcss = wcss;
        this.languageFile = null;
        this.wcss.data.subscribe(data => {
            this.languageFile = this.wcss.getLanguageFile();
        }, error => { });
    }
    transform(key, ...args) {
        let rsp = '';
        if (this.languageFile !== null && typeof this.languageFile !== 'undefined') {
            rsp = this.languageFile[key];
            for (let i = 0; i < args.length; i++) {
                rsp = rsp.replace('{{value' + (i + 1) + '}}', args[i])
                    .replace('{{ value' + (i + 1) + ' }}', args[i]);
            }
        }
        return rsp;
    }
};
TranslateObjectPipe.ctorParameters = () => [
    { type: WebClientService }
];
TranslateObjectPipe = __decorate([
    Pipe({
        name: 'translate_object',
        pure: false
    })
], TranslateObjectPipe);
export { TranslateObjectPipe };
