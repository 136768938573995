import { __decorate } from "tslib";
import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
let CopyToClipboardDirective = class CopyToClipboardDirective {
    constructor() {
        this.copied = new EventEmitter();
    }
    onClick(event) {
        event.preventDefault();
        if (!this.payload) {
            return;
        }
        const listener = (e) => {
            const clipboard = e.clipboardData || window['clipboardData'];
            clipboard.setData('text', this.payload.toString());
            e.preventDefault();
            this.copied.emit(this.payload);
        };
        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
    }
};
__decorate([
    Input('copy-clipboard')
], CopyToClipboardDirective.prototype, "payload", void 0);
__decorate([
    Input('context')
], CopyToClipboardDirective.prototype, "context", void 0);
__decorate([
    Output('copied')
], CopyToClipboardDirective.prototype, "copied", void 0);
__decorate([
    HostListener('click', ['$event'])
], CopyToClipboardDirective.prototype, "onClick", null);
CopyToClipboardDirective = __decorate([
    Directive({ selector: '[copy-clipboard]' })
], CopyToClipboardDirective);
export { CopyToClipboardDirective };
