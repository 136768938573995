import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
let GoogleAnalyticsService = class GoogleAnalyticsService {
    constructor() { }
    pushGAEvents(dataArray) {
        let arrayLength = 0;
        if (dataArray !== null) {
            arrayLength = dataArray.length;
            if (arrayLength > 0) {
                window.dataLayer = window.dataLayer || [];
                for (let i = 0; i < arrayLength; i++) {
                    window.dataLayer.push(dataArray[i]);
                }
            }
        }
    }
};
GoogleAnalyticsService = __decorate([
    Injectable()
], GoogleAnalyticsService);
export { GoogleAnalyticsService };
