import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
let SpinnerService = class SpinnerService {
    constructor() {
        this.status = new Subject();
        this._active = false;
    }
    get active() {
        return this._active;
    }
    set active(v) {
        this._active = v;
        this.status.next(v);
    }
    start() {
        this.active = true;
    }
    stop() {
        this.active = false;
    }
};
SpinnerService = __decorate([
    Injectable()
], SpinnerService);
export { SpinnerService };
