import { __awaiter, __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { EcurrenciesService } from './ecurrencies.service';
import { WebClientService } from '../services/webclient-settings.service';
import * as cryptoAddressValidator from 'multicoin-address-validator';
import { PublicKey } from "@solana/web3.js";
let UtilityService = class UtilityService {
    constructor(ecurrenciesService, webSettingsService) {
        this.ecurrenciesService = ecurrenciesService;
        this.webSettingsService = webSettingsService;
        this.ecurrencies = [];
        this.currency = '';
        this.disabledFromTimer = false;
        this.settingsValue = '';
        this.setTimer();
    }
    convertToShortcut(currency) {
        return new Promise((resolve) => {
            this.ecurrenciesService.getEcurrencies().then((data) => {
                this.ecurrencies = this.ecurrenciesService.getAllEcurrencies();
                for (let i = 0; i < this.ecurrencies.length; i++) {
                    if (currency === this.ecurrencies[i].name) {
                        this.currency = this.ecurrencies[i].code;
                        resolve(this.currency);
                    }
                }
                resolve('');
            });
        });
    }
    checkPhone(phone) {
        if (phone) {
            phone = phone.replace(/\s/g, '');
            const length = phone.length;
            if (length === 12 || length === 13) {
                if (phone.charAt(0) === '+') {
                    for (let i = 1; i < length; i++) {
                        if (isNaN(parseInt(phone[i]))) {
                            return false;
                        }
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    }
    validateAddress(address, addressCurrency, chain = null) {
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            let validDepositAddress = true;
            const currency = addressCurrency.toUpperCase();
            const currencyChain = chain === null || chain === void 0 ? void 0 : chain.toUpperCase();
            if (typeof address !== 'undefined') {
                if (currency === 'SOL' || currencyChain === 'SOL') {
                    yield this.validateSolanaAddress(address).then(data => {
                        validDepositAddress = data;
                    });
                }
                else {
                    if (currencyChain && currencyChain !== currency) {
                        if (currencyChain === 'BSC' || currencyChain === 'AVAXC' || currencyChain === 'ERC20') {
                            validDepositAddress = cryptoAddressValidator.validate(address, 'ETH');
                        }
                        else {
                            validDepositAddress = cryptoAddressValidator.validate(address, currencyChain);
                        }
                    }
                    else {
                        if (currency === 'NEAR') {
                            const regEx = /^[a-z0-9_-]{1}[a-z0-9_.-]{0,62}[a-z0-9_-]{1}$/;
                            validDepositAddress = regEx.test(address);
                        }
                        else if (currency === 'WND') {
                            validDepositAddress = cryptoAddressValidator.validate(address, 'DOT');
                        }
                        else {
                            validDepositAddress = cryptoAddressValidator.validate(address, currency);
                        }
                    }
                }
            }
            resolve({ validDepositAddress });
        }));
    }
    validateSolanaAddress(addr) {
        return __awaiter(this, void 0, void 0, function* () {
            let publicKey;
            try {
                publicKey = new PublicKey(addr);
                return PublicKey.isOnCurve(publicKey);
            }
            catch (err) {
                return false;
            }
        });
    }
    validateBankAccount(bankAccount) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let maxLen;
                let maxLenWithSeparators;
                let validBankAccount = true;
                const lang = localStorage.getItem('language');
                yield this.getBankAccountSettings(lang);
                const array = this.settingsValue.split('-');
                const startParse = parseInt(array[0]);
                const start = !isNaN(startParse) ? startParse : 0;
                const middleParse = parseInt(array[1]);
                const middle = !isNaN(middleParse) ? middleParse : 0;
                const endParse = parseInt(array[2]);
                const end = !isNaN(endParse) ? endParse : 0;
                maxLen = start + middle + end;
                maxLenWithSeparators = maxLen + 2;
                let numOfDigits;
                const bank_account = bankAccount;
                if (typeof bank_account !== 'undefined') {
                    numOfDigits = this._numberOfDigits(bank_account.replace(/-/g, ''));
                }
                else {
                    numOfDigits = 0;
                }
                let tempAccount = '';
                if (typeof bank_account !== 'undefined' && (/^\d+$/.test(bankAccount.replace(/-/g, '')))) {
                    if (numOfDigits < 6) {
                        validBankAccount = false;
                    }
                    else {
                        let howMuch = 0;
                        if ((numOfDigits <= maxLen)) {
                            howMuch = (maxLen - numOfDigits) + 1;
                        }
                        else {
                            bankAccount = bankAccount.substring(0, maxLen);
                            howMuch = 0;
                        }
                        tempAccount = new Array(howMuch).join('0');
                        let newAccount = bankAccount.replace(/-/g, '');
                        newAccount = newAccount.substring(0, start) + tempAccount + newAccount.substring(start, newAccount.length);
                        newAccount = newAccount.substring(0, start) + '-' + newAccount.substring(start, newAccount.length);
                        newAccount = newAccount.substring(0, maxLen - 1) + '-' + newAccount.substring(maxLen - 1, newAccount.length);
                        bankAccount = newAccount;
                        if (!this._validFirstThree(newAccount.substring(0, start))) {
                            validBankAccount = false;
                            resolve({ validBankAccount: validBankAccount, 'formatedBankAccount': bankAccount });
                        }
                        const firstPart = newAccount.replace(/-/g, '').substring(0, maxLen - end);
                        const lastPart = newAccount.replace(/-/g, '').substring(maxLen - end, maxLen);
                        if (this._mod97(firstPart) === parseInt(lastPart)) {
                            validBankAccount = true;
                            bankAccount = newAccount;
                            resolve({ validBankAccount: validBankAccount, 'formatedBankAccount': bankAccount });
                        }
                        else {
                            validBankAccount = false;
                            resolve({ validBankAccount: validBankAccount, 'formatedBankAccount': bankAccount });
                        }
                    }
                }
                else {
                    validBankAccount = false;
                    resolve({ validBankAccount: validBankAccount, 'formatedBankAccount': bankAccount });
                }
                resolve({ validBankAccount: validBankAccount, 'formatedBankAccount': bankAccount });
            }));
        });
    }
    _mod97(br) {
        let kb = 0;
        let os = 100;
        for (let x = br.length - 1; x >= 0; x--) {
            kb = (kb + (os * parseInt(br.charAt(x), 10))) % 97;
            os = (os * 10) % 97;
        }
        kb = 98 - kb;
        return kb;
    }
    _validFirstThree(value) {
        if (value % 5 === 0) {
            return true;
        }
        return false;
    }
    _numberOfDigits(value) {
        return Math.floor(Math.log(value) / Math.LN10 + 1);
    }
    getBankAccountSettings(lang) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.webSettingsService.getSettings().subscribe((data) => {
                this.settingsValue = this.webSettingsService.getCustomValue('SELL.BANK_ACCOUNT_FORMAT.VALUE');
            });
        });
    }
    setTimer() {
        this.timeLeft = this.webSettingsService.getCustomValue('VERIFY.SEND_CODE_WAIT_PERIOD.VALUE');
    }
    goDisableSendSmsButton() {
        this.disabledFromTimer = true;
        this.setTimer();
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            }
            else {
                this.resetTimer();
            }
        }, 1000);
    }
    showTimer() {
        if (this.disabledFromTimer == false) {
            return '';
        }
        else {
            return '...' + this.timeLeft;
        }
    }
    resetTimer() {
        clearInterval(this.interval);
        this.disabledFromTimer = false;
        this.setTimer();
    }
    getCookie(name) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
};
UtilityService.ctorParameters = () => [
    { type: EcurrenciesService },
    { type: WebClientService }
];
UtilityService = __decorate([
    Injectable()
], UtilityService);
export { UtilityService };
