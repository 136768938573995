import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SpinnerService } from '../pages/spinner/spinner.service';
import { PaymentService } from './payment.service';
import { ComponentService } from './component.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginComponent } from '../pages/login';
import { PersonalModalComponent } from '../pages/personal-verification-modal/personal-verification-modal.component';
import { EcurrenciesService } from './ecurrencies.service';
let GoToPageService = class GoToPageService {
    constructor(paymentService, spinner, router, comp, auth, ecurrenciesService) {
        this.paymentService = paymentService;
        this.spinner = spinner;
        this.router = router;
        this.comp = comp;
        this.auth = auth;
        this.ecurrenciesService = ecurrenciesService;
        this.gotoChoosePayment = false;
        this.resultOfService = [];
        this.user = {};
        this.loggedIn = false;
        this.ecurrencies = [];
        this.user = JSON.parse(localStorage.getItem('user'));
        this.auth.getStatus().subscribe((result) => {
            this.loggedIn = result;
        });
    }
    goTo(type, currency, mop) {
        this.spinner.start();
        sessionStorage.setItem('chosenType', type);
        sessionStorage.setItem('chosenCurrency', currency);
        let wrongType = false;
        let wrongCurrency = false;
        let wrongMop = false;
        this.gotoChoosePayment = false;
        if (type !== 'buy' && type !== 'sell') {
            wrongType = true;
        }
        if (mop !== null && mop !== 'bank_slip' && mop !== 'card') {
            wrongMop = true;
        }
        this.ecurrenciesService.getEcurrencies().then((data) => {
            this.ecurrencies = this.ecurrenciesService.getAllEcurrencies();
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.ecurrencies.length; i++) {
                if (currency === this.ecurrencies[i].code) {
                    wrongCurrency = false;
                    break;
                }
                wrongCurrency = true;
            }
            if (!wrongCurrency && !wrongType && !wrongMop) {
                this.paymentService.getMeansOfPayment().subscribe((requestData) => {
                    this.resultOfService = JSON.parse(requestData['_body']);
                    if (JSON.parse(localStorage.getItem('user'))) {
                        this.user = JSON.parse(localStorage.getItem('user'));
                        this.loggedIn = true;
                    }
                    let tmpUrl = '/' + type + '/' + currency;
                    if (mop === null) {
                        if (this.user !== null) {
                            if (this.user['default_mean_of_payment'] === null && this.resultOfService.length > 1) {
                                this.gotoChoosePayment = true;
                            }
                            else if (this.resultOfService.length <= 1) {
                                this.user['default_mean_of_payment'] = this.resultOfService[0].verbose_id;
                            }
                        }
                        if (!this.loggedIn) {
                            this.spinner.stop();
                            this.router.navigateByUrl('/');
                            this.comp.openModal(LoginComponent, null, '/direct/' + type + '/' + currency);
                            return;
                        }
                        if (this.gotoChoosePayment && type === 'buy') {
                            tmpUrl = '/choose' + '/' + currency;
                        }
                        if (type === 'buy' && !this.gotoChoosePayment) {
                            if (this.user !== null && this.user['default_mean_of_payment'] !== null) {
                                tmpUrl = '/' + type + '/' + currency + '/' + this.user['default_mean_of_payment'] + '/buy';
                            }
                        }
                        if (type === 'sell') {
                            tmpUrl = '/' + type + '/' + currency + '/sell';
                        }
                        {
                            if (this.auth.getOrderStatus() && this.user.personal_validation_required === 0) {
                                this.router.navigateByUrl(tmpUrl);
                            }
                            else if (this.auth.getStatus()) {
                                this.spinner.stop();
                                if (this.user.personal_validation_required === 1) {
                                    this.comp.openModal(PersonalModalComponent);
                                }
                            }
                        }
                    }
                    else {
                        if (!this.loggedIn) {
                            this.spinner.stop();
                            this.router.navigateByUrl('/');
                            this.comp.openModal(LoginComponent, null, '/direct/' + type + '/' + currency + '/' + mop);
                            return;
                        }
                        if (type === 'buy') {
                            if (this.user !== null) {
                                tmpUrl = '/' + type + '/' + currency + '/' + mop + '/buy';
                            }
                        }
                        if (type === 'sell') {
                            tmpUrl = '/' + type + '/' + currency + '/sell';
                        }
                        if (this.auth.getOrderStatus() && this.user.personal_validation_required === 0) {
                            this.router.navigateByUrl(tmpUrl);
                        }
                        else if (this.auth.getStatus()) {
                            this.spinner.stop();
                            if (this.user.personal_validation_required === 1) {
                                this.comp.openModal(PersonalModalComponent);
                            }
                        }
                    }
                }, (error) => {
                    this.spinner.stop();
                    console.log(error);
                });
            }
            else {
                this.router.navigateByUrl('/');
            }
        });
    }
};
GoToPageService.ctorParameters = () => [
    { type: PaymentService },
    { type: SpinnerService },
    { type: Router },
    { type: ComponentService },
    { type: AuthService },
    { type: EcurrenciesService }
];
GoToPageService = __decorate([
    Injectable()
], GoToPageService);
export { GoToPageService };
