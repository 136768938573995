import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
let InactiveGuard = class InactiveGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate() {
        this.router.navigate(['/']);
        return false;
    }
};
InactiveGuard.ctorParameters = () => [
    { type: Router }
];
InactiveGuard = __decorate([
    Injectable()
], InactiveGuard);
export { InactiveGuard };
