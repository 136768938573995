import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
let OrderService = class OrderService {
    constructor() {
        this.DRAFT_CREATED = 'DRAFT_CREATED';
        this.CONFIRMED = 'CONFIRMED';
        this.PAYMENT_START = 'PAYMENT_START';
        this.PROCESSING = 'PROCESSING';
        this.RECALCULATE_START = 'RECALCULATE_START';
        this.RECALCULATE_SUCCESS = 'RECALCULATE_SUCCESS';
        this.SEND_TO_USER_START = 'SEND_TO_USER_START';
        this.SEND_TO_USER_ACCEPTED = 'SEND_TO_USER_ACCEPTED';
        this.SEND_TO_USER_SUCCESS = 'SEND_TO_USER_SUCCESS';
        this.MARKET_BUY_START = 'MARKET_BUY_START';
        this.MARKET_BUY_ACCEPTED = 'MARKET_BUY_ACCEPTED';
        this.MARKET_BUY_SUCCESS = 'MARKET_BUY_SUCCESS';
        this.PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
        this.COMPLETED = 'COMPLETED';
    }
};
OrderService = __decorate([
    Injectable()
], OrderService);
export { OrderService };
