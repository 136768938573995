import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
let WebsiteThemeService = class WebsiteThemeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.themeDetails$ = new AsyncSubject();
        this.errorSubject = new BehaviorSubject(false);
        this.value = this.errorSubject.asObservable();
        // kada se dobije error idi na under-maintenance
        this.httpClient.get('themes/gettheme').subscribe((data) => {
            if (!data['content']) {
                this.errorSubject.next(true);
            }
            else {
                this.errorSubject.next(false);
                this.setTheme(data);
            }
        }, (error) => {
            this.errorSubject.next(true);
        });
    }
    getTheme() {
        return this.themeDetails$.asObservable();
    }
    setTheme(data) {
        this.themeDetails$.next(data);
        this.themeDetails$.complete();
    }
    getStyleAttribute(item) {
        this.themeDetails$.subscribe({
            next: (data) => { this.styleAttribute = data.content[item]; }
        });
        return this.styleAttribute;
    }
};
WebsiteThemeService.ctorParameters = () => [
    { type: SharedHttp }
];
WebsiteThemeService = __decorate([
    Injectable()
], WebsiteThemeService);
export { WebsiteThemeService };
