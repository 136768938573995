var SharedHttp_1;
import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { CONFIG } from '../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
let SharedHttp = SharedHttp_1 = class SharedHttp {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getAuthorizationHeader() {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        if (localStorage.getItem('auth_token')) {
            headers = headers.append('Authorization', localStorage.getItem('auth_token'));
            headers = headers.append('auth', localStorage.getItem('auth_token'));
        }
        return headers;
    }
    static getToken() {
        let token = localStorage.getItem('auth_token');
        if (!token || token === '') {
            token = this.readCookie('auth_token');
        }
        return token;
    }
    static readCookie(n) {
        return (n = new RegExp('(?:^|;\\s*)' + ('' + n)
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + '=([^;]*)')
            .exec(document.cookie)) && n[1];
    }
    get(url) {
        const headers = SharedHttp_1.getAuthorizationHeader();
        return this.httpClient.get(SharedHttp_1.serverEndpoint + url, { headers: headers
        });
    }
    post(url, data) {
        const headers = SharedHttp_1.getAuthorizationHeader();
        // data = JSON.stringify(data);
        return this.httpClient.post(SharedHttp_1.serverEndpoint + url, data, {
            headers: headers
        });
    }
    downloadFile(url, data) {
        data = JSON.stringify(data);
        return this.httpClient.post(SharedHttp_1.serverEndpoint + url, data, { headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('auth_token'),
                'auth': localStorage.getItem('auth_token')
            }), observe: 'response', responseType: 'blob'
        });
    }
    upload(data) {
        return this.httpClient.post(SharedHttp_1.uploadEndPoint, data, { headers: new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('auth_token'),
                'auth': localStorage.getItem('auth_token')
            })
        });
    }
    put(url, data) {
        const headers = SharedHttp_1.getAuthorizationHeader();
        // headers.append('Content-Type', 'multipart/form-data');
        data = JSON.stringify(data);
        return this.httpClient.put(SharedHttp_1.serverEndpoint + url, data, {
            headers: headers
        });
    }
    delete(url) {
        const headers = SharedHttp_1.getAuthorizationHeader();
        return this.httpClient.delete(SharedHttp_1.serverEndpoint + url, {
            headers: headers
        });
    }
};
SharedHttp.serverEndpoint = CONFIG['apiUrl'];
SharedHttp.uploadEndPoint = CONFIG['uploadUrl'];
SharedHttp.ctorParameters = () => [
    { type: HttpClient }
];
SharedHttp = SharedHttp_1 = __decorate([
    Injectable()
], SharedHttp);
export { SharedHttp };
