import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
import { WebClientService } from './webclient-settings.service';
let PricesService = class PricesService {
    constructor(httpClient, webSettingsService) {
        this.httpClient = httpClient;
        this.webSettingsService = webSettingsService;
        this.prices = [];
        this.allPrices = {};
        this.changes = [];
        this.allChanges = {};
        this.showLocal = true;
        this.showUsd = true;
        this.primaryCurrency = '';
        this.noPriceMessage = 'n/a';
    }
    getPrices() {
        return new Promise((resolve) => {
            this.httpClient.get('market/prices').subscribe((data) => {
                this.prices = data;
                this.primaryCurrency = this.prices.fiat_currency.toLowerCase();
                resolve(true);
                return data;
            }, (error) => {
                resolve(false);
            });
        });
    }
    getChanges() {
        return new Promise((resolve) => {
            this.httpClient.get('market/prices/changes').subscribe((data) => {
                this.changes = data;
                resolve(true);
                return data;
            }, (error) => {
                resolve(false);
            });
        });
    }
    getAllPrices() {
        this.allPrices.rsd_prices = {};
        this.allPrices.primaryCurrency = this.primaryCurrency;
        const pricesString = '_prices';
        // tslint:disable-next-line: forin
        for (const key in this.prices) {
            if (key.includes(pricesString)) {
                this.allPrices[key] = {};
                for (const currency in this.prices[key]) {
                    if (this.prices[key][currency] < 100 && this.prices[key][currency] >= 2) {
                        this.allPrices[key][currency] = ((Math.round(this.prices[key][currency] * 100) / 100).toFixed(2) + '').replace('.', ',');
                    }
                    else if (this.prices[key][currency] < 2) {
                        this.allPrices[key][currency] = ((Math.round(this.prices[key][currency] * 10000) / 10000).toFixed(4) + '').replace('.', ',');
                    }
                    else {
                        this.allPrices[key][currency] = this.formatNumberRsd(Math.round(this.prices[key][currency]));
                    }
                }
            }
        }
        for (const key in this.prices.rsd_prices) {
            if (this.prices.rsd_prices[key] < 1000) {
                this.allPrices.rsd_prices[key] = ((Math.round(this.prices.rsd_prices[key] * 100) / 100).toFixed(2) + '').replace('.', ',');
            }
            else {
                this.allPrices.rsd_prices[key] = this.formatNumberRsd(Math.round(this.prices.rsd_prices[key]));
            }
        }
        return this.allPrices;
    }
    getArrayPrices() {
        const priceByPrimary = this.primaryCurrency + '_prices';
        this.allPrices.rsd_prices = [];
        this.allPrices[priceByPrimary] = [];
        this.allPrices.primaryCurrency = this.primaryCurrency;
        // tslint:disable-next-line: forin
        for (let key in this.prices.rsd_prices) {
            if (this.prices.rsd_prices[key] < 1000) {
                this.allPrices.rsd_prices.push({
                    key: key,
                    value: ((Math.round(this.prices.rsd_prices[key] * 100) / 100).toFixed(2) + '').replace('.', ',')
                });
            }
            else {
                this.allPrices.rsd_prices.push({
                    key: key,
                    value: this.allPrices[priceByPrimary][key] = this.formatNumberRsd(Math.round(this.prices.rsd_prices[key]))
                });
            }
        }
        for (let key in this.prices[priceByPrimary]) {
            if (this.prices[priceByPrimary][key] < 100 && this.prices[priceByPrimary][key] >= 2) {
                this.allPrices[priceByPrimary].push({
                    key: key,
                    value: ((Math.round(this.prices[priceByPrimary][key] * 100) / 100).toFixed(2) + '').replace('.', ',')
                });
            }
            else if (this.prices[priceByPrimary][key] < 2) {
                this.allPrices[priceByPrimary].push({
                    key: key,
                    value: ((Math.round(this.prices[priceByPrimary][key] * 10000) / 10000).toFixed(4) + '').replace('.', ',')
                });
            }
            else {
                this.allPrices[priceByPrimary].push({
                    key: key,
                    value: this.allPrices[priceByPrimary][key] = this.formatNumberRsd(Math.round(this.prices[priceByPrimary][key]))
                });
            }
        }
        return this.allPrices;
    }
    getAllChanges() {
        // tslint:disable-next-line: forin
        for (let key in this.changes) {
            if (this.changes[key]) {
                if (this.changes[key] > 0) {
                    this.allChanges[key] = '+' + this.changes[key].replace('.', ',') + '%';
                }
                else {
                    this.allChanges[key] = this.changes[key].replace('.', ',') + '%';
                }
            }
            else {
                this.allChanges[key] = this.noPriceMessage;
            }
        }
        return this.allChanges;
    }
    getArrayChanges() {
        this.webSettingsService.getSettings().subscribe((data) => {
            this.allChanges = [];
            this.showLocal = this.webSettingsService.applySettings('PRICES.DISPLAY_LOCAL_CURRENCY.VISIBLE');
            this.showUsd = this.webSettingsService.applySettings('PRICES.DISPLAY_USD_CURRENCY.VISIBLE');
            if (this.showUsd !== this.showLocal) {
                if (this.showUsd) {
                    // tslint:disable-next-line: forin
                    for (let key in this.changes) {
                        this.allChanges.push({
                            key: key,
                            value: this.changes[key] ? this.changes[key].replace('.', ',') + '%' : this.noPriceMessage,
                            up: this.changes[key] > 0,
                            down: this.changes[key] < 0,
                            modifiedValue: this.changes[key] ? this.changes[key].replace('-', '').replace('.', ',') + '%' : this.noPriceMessage
                        });
                    }
                }
                else if (this.showLocal) {
                    // tslint:disable-next-line: forin
                    for (let key in this.changes) {
                        this.allChanges.push({
                            key: key,
                            value: this.changes[key] ? this.changes[key].replace('.', ',') + '%' : this.noPriceMessage,
                            up: this.changes[key] > 0,
                            down: this.changes[key] < 0,
                            modifiedValue: this.changes[key] ? this.changes[key].replace('-', '').replace('.', ',') + '%' : this.noPriceMessage
                        });
                    }
                }
            }
            else {
                // tslint:disable-next-line: forin
                for (let key in this.changes) {
                    this.allChanges.push({
                        key: key,
                        value: this.changes[key] ? this.changes[key].replace('.', ',') + '%' : this.noPriceMessage,
                        up: this.changes[key] > 0,
                        down: this.changes[key] < 0,
                        modifiedValue: this.changes[key] ? this.changes[key].replace('-', '').replace('.', ',') + '%' : this.noPriceMessage
                    });
                }
            }
        });
        return this.allChanges;
    }
    formatNumberRsd(val) {
        return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
};
PricesService.ctorParameters = () => [
    { type: SharedHttp },
    { type: WebClientService }
];
PricesService = __decorate([
    Injectable()
], PricesService);
export { PricesService };
