import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
let PaymentService = class PaymentService {
    // tslint:disable-next-line:no-empty
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.meansObs$ = new ReplaySubject(1);
    }
    getMeansOfPayment() {
        return this.httpClient.get('meansofpayment').pipe(map((data) => {
            let cardsAreOn = 'false';
            const resultOfService = Object.values(data);
            for (var i = 0; i < resultOfService.length; i++) {
                if (resultOfService[i].verbose_id === 'card') {
                    cardsAreOn = 'true';
                    break;
                }
            }
            localStorage.setItem('cardsActive', cardsAreOn);
            return { "_body": JSON.stringify(resultOfService) };
        }));
    }
    getMeansOfPayment2(forceRefresh) {
        forceRefresh = true;
        if (!this.meansObs$.observers.length || forceRefresh) {
            this.httpClient.get('meansofpayment').subscribe(data => this.meansObs$.next(data), error => {
                this.meansObs$.error(error);
                this.meansObs$ = new ReplaySubject(1);
            });
        }
        return this.meansObs$;
    }
};
PaymentService.ctorParameters = () => [
    { type: SharedHttp }
];
PaymentService = __decorate([
    Injectable()
], PaymentService);
export { PaymentService };
