var ComponentService_1;
import { __decorate } from "tslib";
import { ComponentRef, Injectable, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
let ComponentService = ComponentService_1 = class ComponentService {
    constructor(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
        ComponentService_1.staticCFR = componentFactoryResolver;
    }
    static setViewContainerRef(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    static getComponentFactoryResolver() {
        return this.staticCFR;
    }
    static getViewContainerRef() {
        return this.viewContainerRef;
    }
    openModal(component, data, success, error) {
        if (this.componentRef) {
            document.body.className += '';
        }
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = ComponentService_1.viewContainerRef.createComponent(factory);
        this.componentRef.instance.modal = this.componentRef;
        if (data) {
            this.componentRef.instance.data = data;
        }
        if (success) {
            this.componentRef.instance.success = success;
        }
        if (error) {
            this.componentRef.instance.error = error;
        }
    }
    closeModal() {
        this.componentRef.destroy();
    }
    ngOnDestroy() {
        document.body.className = '';
    }
};
ComponentService.ctorParameters = () => [
    { type: ComponentFactoryResolver }
];
ComponentService = ComponentService_1 = __decorate([
    Injectable()
], ComponentService);
export { ComponentService };
