import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { SharedHttp } from './http-client.service';
let EcurrenciesService = class EcurrenciesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.ecurrencies = [];
    }
    getEcurrencies() {
        return new Promise((resolve) => {
            this.httpClient.get('market/ecurrencies').subscribe((data) => {
                this.ecurrencies = data;
                if (this.ecurrencies.length === 0) {
                    resolve(false);
                }
                else {
                    resolve(true);
                }
                return data;
            }, (error) => {
                resolve(false);
            });
        });
    }
    getAllEcurrencies() {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.ecurrencies.length; i++) {
            this.ecurrencies[i].image_url = (this.ecurrencies[i].image_url === null) ?
                'assets/img/icons/lupa.svg' : this.ecurrencies[i].image_url;
            if (this.ecurrencies[i].color != null) {
                this.ecurrencies[i].color = (this.ecurrencies[i].color.charAt(0) !== '#') ?
                    '#' + this.ecurrencies[i].color : this.ecurrencies[i].color;
            }
            else {
                this.ecurrencies[i].color = '#f07d00';
            }
        }
        return this.ecurrencies;
    }
};
EcurrenciesService.ctorParameters = () => [
    { type: SharedHttp }
];
EcurrenciesService = __decorate([
    Injectable()
], EcurrenciesService);
export { EcurrenciesService };
