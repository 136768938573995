import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
let OrderGuard = class OrderGuard {
    constructor(_authService) {
        this._authService = _authService;
    }
    canActivate() {
        if (this._authService.isAuthenticated()) {
            return this._authService.ordersAllowed();
        }
    }
};
OrderGuard.ctorParameters = () => [
    { type: AuthService }
];
OrderGuard = __decorate([
    Injectable()
], OrderGuard);
export { OrderGuard };
