import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './shared/pages/home';
import { BuyComponent } from './shared/pages/shop/buy/buy';
import { ConfirmBuyComponent } from './shared/pages/shop/buy/confirm';
import { EndBuyComponent } from './shared/pages/shop/buy/end';
import { HomeBuyComponent } from './shared/pages/shop/buy';
import { SellComponent } from './shared/pages/shop/sell/sell';
import { ConfirmSellComponent } from './shared/pages/shop/sell/confirm';
import { EndSellComponent } from './shared/pages/shop/sell/end';
import { HomeSellComponent } from './shared/pages/shop/sell';
import { ContactComponent } from './shared/pages/contact';
import { ConsultationComponent } from './shared/pages/consultation';
import { SingleConsultationComponent } from './shared/pages/single-consultation';
import { AccountComponent } from './shared/pages/account';
import { FaqComponent } from './shared/pages/faq';
import { ProvisionsComponent } from './shared/pages/provisions';
import { TransactionsComponent } from './shared/pages/transactions';
import { CryptoAtmsComponent } from './shared/pages/crypto-atms';
import { HashAndGoComponent } from './shared/pages/hash-and-go';
import { NewsComponent } from './shared/pages/news';
import { AboutComponent } from './shared/pages/about';
import { PendingScreenComponent } from './shared/pages/pending-screen';
import { UploadDocumentScreenComponent } from './shared/pages/upload-document-screen';
import { KycQuestionnaireComponent } from './shared/pages/kyc-questionnaire';
import { StandingOrderComponent } from './shared/pages/standing-order';
import { NotificationsComponent } from './shared/pages/account/notifications';
import { AccountsComponent } from './shared/pages/account/accounts';
import { DataComponent } from './shared/pages/account/data';
import { ChangePasswordComponent } from './shared/pages/account/change-password';
import { ChangeMobileComponent } from './shared/pages/account/change-mobile';
import { AuthGuard } from './shared/services/auth.guard';
import { OrderGuard } from './shared/services/order.guard';
import { VerifyComponent } from './shared/pages/verify';
import { ResetComponent } from './shared/pages/reset';
import { ActivateComponent } from './shared/pages/activate';
import { ActivateDeviceComponent } from './shared/pages/activate-device';
import { EducationComponent } from './shared/pages/education';
import { SEO } from './../config';
import { VoucherComponent } from './shared/pages/voucher';
import { WebShopComponent } from './shared/pages/webshop';
import { CartComponent } from './shared/pages/webshop/components/cart';
import { CheckoutComponent } from './shared/pages/webshop/components/checkout';
import { ConfirmShopComponent } from './shared/pages/webshop/components/confirm';
import { EndShopComponent } from './shared/pages/webshop/components/end';
import { Pay2MeComponent } from './shared/pages/pay2me';
import { ChoosePayingComponent } from './shared/pages/choose-paying';
import { PreconfirmComponent } from './shared/pages/preconfirm';
import { EcdServicesComponent } from './shared/pages/ecd-services';
import { ReferralsComponent } from './shared/pages/referrals';
import { SuccessComponent } from './shared/pages/voucher/success';
import { CreateTransactionComponent } from './shared/pages/create-guest-transactions/create-transaction';
import { ConfirmGuestComponent } from './shared/pages/create-guest-transactions/confirm';
import { EndGuestComponent } from './shared/pages/create-guest-transactions/end';
import { MetaGuard } from 'ng2-meta';
import { GoToPageComponent } from './shared/pages/go-to-page';
import { LockedUserActivationComponent } from './shared/pages/locked-user-activation';
import { QuestionnaireComponent } from './shared/pages/questionnaire';
import { DocumentationComponent } from "./shared/pages/documentation";
import { NewBankAccountComponent } from './shared/pages/account/new-bank-account';
import { NewEAccountComponent } from './shared/pages/account/new-eaccount';
import { CompanyDataComponent } from './shared/pages/account/company-data';
import { WalletComponent } from "./shared/pages/wallet";
import { WalletTransactionsComponent } from "./shared/pages/wallet/wallet-transactions";
import { WalletSettingsComponent } from "./shared/pages/wallet/wallet-settings";
import { WalletCryptocurrenciesComponent } from "./shared/pages/wallet/wallet-cryptocurrencies";
import { AuthenticationComponent } from "./shared/pages/account/authentication";
import { ContractComponent } from "./shared/pages/account/contract/contract.component";
import { CampaignsComponent } from "./shared/pages/campaigns/campaigns.component";
import { SubscribeUnregisteredComponent } from "./shared/pages/subscribe-unregistered/subscribe-unregistered.component";
import { PromotionComponent } from "./shared/pages/promotion";
import { ChangeWalletPasswordComponent } from "./shared/pages/account/change-wallet-password";
import { InactiveGuard } from "./shared/services/inactive.guard";
import { CoinDetailsComponent } from "./shared/pages/wallet/coin-details";
export const ROUTES = [
    { path: '', component: HomeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    {
        path: 'registration', component: HomeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    {
        path: 'login', component: HomeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    { path: 'education', component: EducationComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['education']['title'],
                description: SEO['education']['description'],
                keywords: SEO['education']['keywords']
            }
        }
    },
    { path: 'shop/end', component: EndShopComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'pay2me', component: Pay2MeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['pay2me']['title'],
                description: SEO['pay2me']['description'],
                keywords: SEO['pay2me']['keywords']
            }
        }
    },
    { path: 'refId/:refId', component: HomeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        } },
    { path: 'resetpassword/:hash', component: ResetComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['reset']['title'],
                description: SEO['reset']['description'],
                keywords: SEO['reset']['keywords']
            }
        }
    },
    { path: 'user/activate/:hash', component: ActivateComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    { path: 'user/activatedevice/:hash', component: ActivateDeviceComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    { path: 'unlockuser/:hash', component: LockedUserActivationComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'data', pathMatch: 'full', },
            { path: 'data', component: DataComponent, data: { meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'notifications', component: NotificationsComponent, canActivate: [MetaGuard],
                data: { meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'contract', component: ContractComponent, canActivate: [MetaGuard],
                data: { meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'accounts', component: AccountsComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                }
            },
            { path: 'change-password', component: ChangePasswordComponent, canActivate: [MetaGuard],
                data: { meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'change-mobile', component: ChangeMobileComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'change-wallet-password', component: ChangeWalletPasswordComponent, canActivate: [MetaGuard],
                data: { meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'new-bank-account', component: NewBankAccountComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'new-eaccount', component: NewEAccountComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'company-data', component: CompanyDataComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'authentication', component: AuthenticationComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['account']['title'],
                        description: SEO['account']['description'],
                        keywords: SEO['account']['keywords']
                    }
                } },
            { path: 'reset-passphrase/:token', component: DataComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            }
        ],
        data: {
            meta: {
                title: SEO['account']['title'],
                description: SEO['account']['description'],
                keywords: SEO['account']['keywords']
            }
        }
    },
    { path: 'faq', component: FaqComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['faq']['title'],
                description: SEO['faq']['description'],
                keywords: SEO['faq']['keywords']
            }
        }
    },
    { path: 'fees', component: ProvisionsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['provisions']['title'],
                description: SEO['provisions']['description'],
                keywords: SEO['provisions']['keywords']
            }
        }
    },
    { path: 'fees/:userType', component: ProvisionsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['provisions']['title'],
                description: SEO['provisions']['description'],
                keywords: SEO['provisions']['keywords']
            }
        }
    },
    { path: 'consulting', component: ConsultationComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['consultation']['title'],
                description: SEO['consultation']['description'],
                keywords: SEO['consultation']['keywords']
            }
        }
    },
    { path: 'consultation/:id', component: SingleConsultationComponent, canActivate: [MetaGuard],
        children: [
            { path: '', redirectTo: 'single', pathMatch: 'full' },
        ]
    },
    { path: 'buy/end/:order_id', component: EndBuyComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['buy']['title'],
                description: SEO['buy']['description'],
                keywords: SEO['buy']['keywords']
            }
        }
    },
    { path: 'buy/end/:outcome/:order_id', component: EndBuyComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['buy']['title'],
                description: SEO['buy']['description'],
                keywords: SEO['buy']['keywords']
            }
        }
    },
    { path: 'buy', component: HomeBuyComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'buy', pathMatch: 'full' },
            { path: ':id/:mp/buy', component: BuyComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['buy']['title'],
                        description: SEO['buy']['description'],
                        keywords: SEO['buy']['keywords']
                    }
                }
            },
            { path: 'confirm/:orderId', component: ConfirmBuyComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['buy']['title'],
                        description: SEO['buy']['description'],
                        keywords: SEO['buy']['keywords']
                    }
                }
            },
        ],
        data: {
            meta: {
                title: SEO['buy']['title'],
                description: SEO['buy']['description'],
                keywords: SEO['buy']['keywords']
            }
        }
    },
    { path: 'sell', component: HomeSellComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'sell', pathMatch: 'full' },
            { path: ':id/sell', component: SellComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['sell']['title'],
                        description: SEO['sell']['description'],
                        keywords: SEO['sell']['keywords']
                    }
                }
            },
            { path: 'confirm/:orderId', component: ConfirmSellComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['sell']['title'],
                        description: SEO['sell']['description'],
                        keywords: SEO['sell']['keywords']
                    }
                }
            },
        ],
        data: {
            meta: {
                title: SEO['sell']['title'],
                description: SEO['sell']['description'],
                keywords: SEO['sell']['keywords']
            }
        }
    },
    { path: 'sell/end/:orderId', component: EndSellComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['sell']['title'],
                description: SEO['sell']['description'],
                keywords: SEO['sell']['keywords']
            }
        }
    },
    { path: 'sell/end/:outcome/:orderId', component: EndSellComponent, canActivate: [AuthGuard, OrderGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['sell']['title'],
                description: SEO['sell']['description'],
                keywords: SEO['sell']['keywords']
            }
        }
    },
    { path: 'contact', component: ContactComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['contact']['title'],
                description: SEO['contact']['description'],
                keywords: SEO['contact']['keywords']
            }
        }
    },
    { path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard, MetaGuard],
        data: {
            meta: {
                title: SEO['transactions']['title'],
                description: SEO['transactions']['description'],
                keywords: SEO['transactions']['keywords']
            }
        }
    },
    { path: 'shop', component: WebShopComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'cart', component: CartComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'checkout', component: CheckoutComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'shop/confirm', component: ConfirmShopComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'shop/:id', component: WebShopComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['shop']['title'],
                description: SEO['shop']['description'],
                keywords: SEO['shop']['keywords']
            }
        }
    },
    { path: 'crypto-atms', component: CryptoAtmsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['crypto_atms']['title'],
                description: SEO['crypto_atms']['description'],
                keywords: SEO['crypto_atms']['keywords']
            }
        }
    },
    { path: 'hash-and-go', component: HashAndGoComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['hash_go']['title'],
                description: SEO['hash_go']['description'],
                keywords: SEO['hash_go']['keywords']
            }
        }
    },
    { path: 'consultation', component: ConsultationComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['consult']['title'],
                description: SEO['consult']['description'],
                keywords: SEO['consult']['keywords']
            }
        }
    },
    { path: 'news', component: NewsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['news']['title'],
                description: SEO['news']['description'],
                keywords: SEO['news']['keywords']
            }
        }
    },
    { path: 'news/:id', component: NewsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['news']['title'],
                description: SEO['news']['description'],
                keywords: SEO['news']['keywords']
            }
        }
    },
    { path: 'standing-order', component: StandingOrderComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['standing']['title'],
                description: SEO['standing']['description'],
                keywords: SEO['standing']['keywords']
            }
        }
    },
    { path: 'about', component: AboutComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['about']['title'],
                description: SEO['about']['description'],
                keywords: SEO['about']['keywords']
            }
        }
    },
    { path: 'pending', component: PendingScreenComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['about']['title'],
                description: SEO['about']['description'],
                keywords: SEO['about']['keywords']
            }
        }
    },
    { path: 'kycdocumentation', component: UploadDocumentScreenComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['about']['title'],
                description: SEO['about']['description'],
                keywords: SEO['about']['keywords']
            }
        }
    },
    { path: 'kycquestionnaire', component: KycQuestionnaireComponent, canActivate: [MetaGuard, InactiveGuard],
        data: {
            meta: {
                title: SEO['questionnaire']['title'],
                description: SEO['questionnaire']['description'],
                keywords: SEO['questionnaire']['keywords']
            }
        }
    },
    { path: 'questionnaire', component: QuestionnaireComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['questionnaire']['title'],
                description: SEO['questionnaire']['description'],
                keywords: SEO['questionnaire']['keywords']
            }
        }
    },
    { path: 'documentation/:orderId', component: DocumentationComponent, canActivate: [MetaGuard, AuthGuard],
        data: {
            meta: {
                title: SEO['documentation']['title'],
                description: SEO['documentation']['description'],
                keywords: SEO['documentation']['keywords']
            }
        }
    },
    {
        path: 'wallet', component: WalletComponent, canActivate: [AuthGuard, MetaGuard],
        children: [
            { path: '', redirectTo: 'cryptocurrencies', pathMatch: 'full' },
            {
                path: 'cryptocurrencies', component: WalletCryptocurrenciesComponent, canActivate: [AuthGuard, MetaGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            },
            {
                path: 'transactions', component: WalletTransactionsComponent, canActivate: [AuthGuard, MetaGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            },
            {
                path: 'transactions/coin-details', component: CoinDetailsComponent, canActivate: [AuthGuard, MetaGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            },
            {
                path: 'settings', component: WalletSettingsComponent, canActivate: [AuthGuard, MetaGuard, InactiveGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            },
            {
                path: 'reset-passphrase/:token', component: WalletCryptocurrenciesComponent, canActivate: [AuthGuard, MetaGuard],
                data: {
                    meta: {
                        title: SEO['wallet']['title'],
                        description: SEO['wallet']['description'],
                        keywords: SEO['wallet']['keywords']
                    }
                }
            }
        ],
        data: {
            meta: {
                title: SEO['wallet']['title'],
                description: SEO['wallet']['description'],
                keywords: SEO['wallet']['keywords']
            }
        }
    },
    { path: 'documentation/:orderId', component: DocumentationComponent, canActivate: [MetaGuard, AuthGuard],
        data: {
            meta: {
                title: SEO['documentation']['title'],
                description: SEO['documentation']['description'],
                keywords: SEO['documentation']['keywords']
            }
        }
    },
    { path: 'verify', component: VerifyComponent, canActivate: [AuthGuard, MetaGuard],
        data: {
            meta: {
                title: SEO['verify']['title'],
                description: SEO['verify']['description'],
                keywords: SEO['verify']['keywords']
            }
        }
    },
    { path: 'voucher', component: VoucherComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['voucher']['title'],
                description: SEO['voucher']['description'],
                keywords: SEO['voucher']['keywords']
            }
        }
    },
    { path: 'voucher/success', component: SuccessComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['voucher']['title'],
                description: SEO['voucher']['description'],
                keywords: SEO['voucher']['keywords']
            }
        }
    },
    { path: 'choose/:id', component: ChoosePayingComponent, canActivate: [AuthGuard, MetaGuard],
        data: {
            meta: {
                title: SEO['choose']['title'],
                description: SEO['choose']['description'],
                keywords: SEO['choose']['keywords']
            }
        }
    },
    { path: 'preconfirm/:tkn', component: PreconfirmComponent, canActivate: [MetaGuard], },
    {
        path: 'ecd-services', component: EcdServicesComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['ecd_services']['title'],
                description: SEO['ecd_services']['description'],
                keywords: SEO['ecd_services']['keywords']
            }
        }
    },
    { path: 'referrals', component: ReferralsComponent, canActivate: [AuthGuard, MetaGuard],
        data: {
            meta: {
                title: SEO['referrals']['title'],
                description: SEO['referrals']['description'],
                keywords: SEO['referrals']['keywords']
            }
        }
    },
    { path: 'tx', component: CreateTransactionComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['guest']['title'],
                description: SEO['guest']['description'],
                keywords: SEO['guest']['keywords']
            }
        }
    },
    { path: 'tx/confirm/:orderId', component: ConfirmGuestComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['guest']['title'],
                description: SEO['guest']['description'],
                keywords: SEO['guest']['keywords']
            }
        }
    },
    { path: 'tx/end/:orderId', component: EndGuestComponent, canActivate: [MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['guest']['title'],
                description: SEO['guest']['description'],
                keywords: SEO['guest']['keywords']
            }
        }
    },
    { path: 'tx/end/:outcome/:orderId', component: EndGuestComponent, canActivate: [MetaGuard],
        children: [
            { path: '', redirectTo: 'end', pathMatch: 'full' },
        ],
        data: {
            meta: {
                title: SEO['guest']['title'],
                description: SEO['guest']['description'],
                keywords: SEO['guest']['keywords']
            }
        }
    },
    {
        path: 'direct/:orderType/:currency/:mop', component: GoToPageComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    {
        path: 'campaign/:hash', component: CampaignsComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['campaign']['title'],
                description: SEO['campaign']['description'],
                keywords: SEO['campaign']['keywords']
            }
        }
    },
    {
        path: 'promotion', component: PromotionComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['promotion']['title'],
                description: SEO['promotion']['description'],
                keywords: SEO['promotion']['keywords']
            }
        }
    },
    {
        path: 'direct/:orderType/:currency', component: GoToPageComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    {
        path: 'subscribe-unregistered/:hash', component: SubscribeUnregisteredComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['subscribe_unregistered']['title'],
                description: SEO['subscribe_unregistered']['description'],
                keywords: SEO['subscribe_unregistered']['keywords']
            }
        }
    },
    {
        path: 'help', component: HomeComponent, canActivate: [MetaGuard],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    {
        path: 'approve-address/:currency', component: HomeComponent, canActivate: [MetaGuard],
        children: [
            { path: ':status', component: HomeComponent, canActivate: [MetaGuard],
                data: {
                    meta: {
                        title: SEO['home']['title'],
                        description: SEO['home']['description'],
                        keywords: SEO['home']['keywords']
                    }
                }
            },
        ],
        data: {
            meta: {
                title: SEO['home']['title'],
                description: SEO['home']['description'],
                keywords: SEO['home']['keywords']
            }
        }
    },
    { path: '**', redirectTo: '' }
];
ROUTES.forEach(route => {
    if (SEO['smart_banner']) {
        if (route.data && route.data.meta) {
            route.data.meta = Object.assign(Object.assign({}, route.data.meta), SEO['smart_banner']);
        }
        if (route.children) {
            route.children.forEach(childRoute => {
                if (childRoute.data && childRoute.data.meta) {
                    childRoute.data.meta = Object.assign(Object.assign({}, childRoute.data.meta), SEO['smart_banner']);
                }
            });
        }
    }
});
let AppRoutingModule = class AppRoutingModule {
};
AppRoutingModule = __decorate([
    NgModule({
        imports: [RouterModule.forRoot(ROUTES)],
        exports: [RouterModule]
    })
], AppRoutingModule);
export { AppRoutingModule };
